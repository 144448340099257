import React, { useContext, useRef, useEffect, useState } from "react"
import { graphql } from "gatsby"

import { MainContext } from "context/context"
import PageWrapper from 'components/globals/pageWrapper'
import ParallaxSection from 'components/globals/parallaxSection'

import { stripHtml } from "utils"

import BlogIndexPost from 'components/flexibleContent/blogIndexPost'
import GenericTextHero from "components/globals/genericTextHero"

import { useLocation } from "@reach/router"

export default ({ data }) => {
	const {
		post: {
			databaseId,
			title,
			content,
			excerpt,
			featuredImage: _featuredImage,
			categories,
			fields: {
				heroImage: _heroImage,
				author,
				writtenBy,
				darkenHero,
				hidePost
			}
		},
		posts
	} = data.siteData

	const featuredImage = _featuredImage ? _featuredImage.node : null

	const location = useLocation()

	const {
		addInvertedHeaderSection,
	} = useContext(MainContext)

	const elementRef = useRef(null)

	const [hasMounted, setHasMounted] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			if (elementRef && elementRef.current && !darkenHero) {
				addInvertedHeaderSection(elementRef.current)
			}

			setHasMounted(true)

			setTimeout(() => {
				const cursorWrapper = document.getElementById('cursor-wrapper')
				cursorWrapper.classList.add('inverted')
			}, 300)
		}, 100)
	}, [elementRef])

	const otherPostsToShow = []

	posts.edges.forEach(({ node: otherPost }) => {
		if (otherPost.databaseId !== databaseId && otherPostsToShow.length < 3) {
			otherPostsToShow.push(otherPost)
		}
	})

	const heroImage = (() => {
		let image = ''

		if (_heroImage && _heroImage.mediaItemUrl) {
			image = _heroImage.mediaItemUrl
		} else if (featuredImage) {
			image = featuredImage.mediaItemUrl
		}

		return image
	})()

	const nextPosts = (() => {
		const _posts = [...posts.edges.map(edge => edge.node)].reverse()

		const _nextPosts = []

		let postIncrementer = 0
		let hasReachedEnd = false

		while (_nextPosts.length < 3) {
			const post = _posts[postIncrementer]

			if (post.databaseId > databaseId || hasReachedEnd) {
				if (post.databaseId !== databaseId) {
					_nextPosts.push(post)
				}

				postIncrementer++
			} else {
				hasReachedEnd = true
			}
		}

		return _nextPosts
	})()

	const currentUrl = location.pathname

	const shareIcons = [
		{
			icon: 'facebook',
			url: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`
		}, {
			icon: 'linkedin',
			url: `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&amp;title=${title}`
		}, {
			icon: 'twitter',
			url: `https://twitter.com/intent/tweet?text=${title}&amp;url=${currentUrl}`
		}
	]

	return (
		<PageWrapper
			title={title}
			description={stripHtml(excerpt)}
			image={featuredImage ? featuredImage.mediaItemUrl : null}
			noIndex={hidePost}
		>
			<section
				className={`single-hero inverted-cursor ${hasMounted ? 'mounted' : ''} ${ darkenHero ? 'darken-hero' : ''}`}
				data-scroll-section
				ref={ref => elementRef.current = ref}
			>
				<ParallaxSection
					backgroundUrl={heroImage}
				>
					<div className={'container deep'}>
						<div className={'container-inner'}>
							<div className={'meta'}>
								<span className={'category-tag'}>
									{categories.edges[0].node.name}
								</span>
								<span>
									{author}
								</span>
							</div>
							<h1 dangerouslySetInnerHTML={{ __html: title }} />
						</div>
					</div>
				</ParallaxSection>
			</section>
			<section
				className={'single-content container deep'}
				data-scroll-section
				id={'single-content-main'}
			>
				<div className={'single-content-main'}>
					<div className={'copy medium'} dangerouslySetInnerHTML={{ __html: content }} />
				</div>
				<aside>
					<div className={'sticky'} data-scroll data-scroll-sticky data-scroll-target="#single-content-main">
						<p className={'medium'}>
							Share:
						</p>
						<ul>
							{shareIcons.map(({
								icon,
								url
							}) => {
								return (
									<li key={icon}>
										<a className={'has-cursor-effect inverted-cursor'} href={url} rel="noopener noreferrer" target="_blank">
											<i className={`fa fa-${icon}`} />
										</a>
									</li>
								)
							})}
						</ul>
						{writtenBy &&
							<>
								<p className={'medium'}>
									Written by:
							</p>
								<div className={'written-by'}>
									<div className={'written-by-image'}>
										<img
											src={writtenBy.featuredImage.node.mediaItemUrl}
											alt={writtenBy?.fields?.authorBio}
										/>
									</div>
									<div className={'written-by-content'}>
										<p className={'medium'} dangerouslySetInnerHTML={{ __html: writtenBy.title }} />
										{writtenBy.fields.authorBio &&
											<span className={'subtitle'} dangerouslySetInnerHTML={{ __html: writtenBy.fields.authorBio }} />
										}
									</div>
								</div>
							</>
						}
					</div>
				</aside>
			</section>
			<div data-scroll-section>
				<section className={'other-posts container deep'}>
					<h2>
						Read on
					</h2>
					<ul className="blog-remainder">
						{nextPosts.map(post => {
							return (
								<BlogIndexPost list {...post} key={post.databaseId} />
							)
						})}
					</ul>
				</section>
				<GenericTextHero />
			</div>
		</PageWrapper>
	)
}


export const query = graphql`
  query($databaseId: ID!) {
    siteData {
      post(id: $databaseId, idType: DATABASE_ID) {
        id
        databaseId
		title
		slug
        date
        link
        content(format: RENDERED)
        categories {
          edges {
            node {
              name
              slug
            }
          }
        }
        excerpt(format: RENDERED)
        featuredImage {
			node {
          		altText
          		title(format: RENDERED)
          		mediaItemUrl
		  		slug
			}
		}
        fields {
			writtenBy {
				...on WPGraphQL_Person {
					id
					databaseId
					title
					featuredImage {
						node {
							mediaItemUrl
						}
					}
					fields {
						authorBio
					}
				}
			}
			darkenHero
            author
            heroImage {
                mediaItemUrl
            }
			hidePost
        }
      }
      posts {
        edges {
          node {
            id
            databaseId
            link
			title
			slug
            excerpt
            date
            featuredImage {
				node {
					mediaItemUrl
				}
            }
            categories {
              nodes {
                name
                slug
              }
            }
			fields {
				author
				heroImage {
					mediaItemUrl
				}
				hidePost
			}
          }
        }
      }
    }
  }
`